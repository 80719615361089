<template>
  <v-card width="350" class="align-center  d-flex flex-column">
    <v-card-title class="mb-8">
      <div>
        <v-img :src="require('@/assets/logo-grupo-cometa.svg')"> </v-img>
      </div>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <h2 class="mb-5">Redifinir Senha</h2>
        <v-text-field
          v-model="form.senha"
          class="mb-2"
          label="Senha"
          outlined
          :rules="[rules.required, rules.password]"
          hint="Minino de  8 characters"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
        <v-text-field
          class="mb-2"
          label="Confirmar Senha"
          outlined
          v-model="confirmSenha"
          :rules="[rules.required, rulesPassword]"
          type="password"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-row class="col-md-12 col-sm-12">
      <v-btn
        @click="confirmResetPassword()"
        big
        block
        :disabled="!valid"
        color="#0d47a1"
        class="mb-2 white--text"
        >Enviar
      </v-btn>
    </v-row>
    <p>
      Tempo expirou?
      <a @click="goToResetPassword()">
        Clique para reenviar
      </a>
    </p>
    <v-overlay :value="load">
      <v-progress-circular size="130" color="white" indeterminate>
        Redefinindo...
      </v-progress-circular>
    </v-overlay>
    <ModalRedirectLogin
      title="Senha redefinida com sucesso!"
      text="Você será derirecionado para a tela de LOGIN..."
      :dialog="modalRedirectDialog"
    />
  </v-card>
</template>

<script>
import loginService from "../../services/login";
import rules from "../../mixins/inputRules";
export default {
  name: "redefinir-senha",
  components: {
    ModalRedirectLogin: () => import("./ModalRedirectLogin")
  },
  mixins: [rules],
  data() {
    return {
      modalRedirectDialog: false,
      showPassword: false,
      load: false,
      valid: false,
      confirmSenha: null,
      form: {
        senha: null,
        token: this.$route.params.token
      },
      rulesPassword: value =>
        value == this.form.senha || "As senha são diferentes!"
    };
  },
  methods: {
    async confirmResetPassword() {
      try {
        this.load = true;
        await loginService.confirmResetPassword(this.form);
        this.$notify({
          text: "Senha redefinida com sucesso!",
          type: "success"
        });
        this.modalRedirectDialog = true;
      } catch (error) {
        this.$notify({
          text: "Erro ao redefinir senha",
          type: "error"
        });
      } finally {
        this.load = false;
      }
    },
    goToResetPassword() {
      this.$router.push("/cadastro/solicita-redefinicao-senha");
    }
  }
};
</script>

<style></style>
